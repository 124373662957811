var currencyList = [
    {"label":"Afghan Afghani","value":"AFA","symbol":"؋","id":1},
    {"label":"Albanian Lek","value":"ALL","symbol":"Lek","id":2},
    {"label":"Algerian Dinar","value":"DZD","symbol":"دج","id":3},
    {"label":"Angolan Kwanza","value":"AOA","symbol":"Kz","id":4},
    {"label":"Argentine Peso","value":"ARS","symbol":"$","id":5},
    {"label":"Armenian Dram","value":"AMD","symbol":"֏","id":6},
    {"label":"Aruban Florin","value":"AWG","symbol":"ƒ","id":7},
    {"label":"Australian Dollar","value":"AUD","symbol":"$","id":8},
    {"label":"Azerbaijani Manat","value":"AZN","symbol":"m","id":9},
    {"label":"Bahamian Dollar","value":"BSD","symbol":"B$","id":10},
    {"label":"Bahraini Dinar","value":"BHD","symbol":".د.ب","id":11},
    {"label":"Bangladeshi Taka","value":"BDT","symbol":"৳","id":12},
    {"label":"Barbadian Dollar","value":"BBD","symbol":"Bds$","id":13},
    {"label":"Belarusian Ruble","value":"BYR","symbol":"Br","id":14},
    {"label":"Belgian Franc","value":"BEF","symbol":"fr","id":15},
    {"label":"Belize Dollar","value":"BZD","symbol":"$","id":16},
    {"label":"Bermudan Dollar","value":"BMD","symbol":"$","id":17},
    {"label":"Bhutanese Ngultrum","value":"BTN","symbol":"Nu.","id":18},
    {"label":"Bitcoin","value":"BTC","symbol":"฿","id":19},
    {"label":"Bolivian Boliviano","value":"BOB","symbol":"Bs.","id":20},
    {"label":"Bosnia-Herzegovina Convertible Mark","value":"BAM","symbol":"KM","id":21},
    {"label":"Botswanan Pula","value":"BWP","symbol":"P","id":22},
    {"label":"Brazilian Real","value":"BRL","symbol":"R$","id":23},
    {"label":"British Pound Sterling","value":"GBP","symbol":"£","id":24},
    {"label":"Brunei Dollar","value":"BND","symbol":"B$","id":25},
    {"label":"Bulgarian Lev","value":"BGN","symbol":"Лв.","id":26},
    {"label":"Burundian Franc","value":"BIF","symbol":"FBu","id":27},
    {"label":"Cambodian Riel","value":"KHR","symbol":"KHR","id":28},
    {"label":"Canadian Dollar","value":"CAD","symbol":"$","id":29},
    {"label":"Cape Verdean Escudo","value":"CVE","symbol":"$","id":30},
    {"label":"Cayman Islands Dollar","value":"KYD","symbol":"$","id":31},
    {"label":"CFA Franc BCEAO","value":"XOF","symbol":"CFA","id":32},
    {"label":"CFA Franc BEAC","value":"XAF","symbol":"FCFA","id":33},
    {"label":"CFP Franc","value":"XPF","symbol":"₣","id":34},
    {"label":"Chilean Peso","value":"CLP","symbol":"$","id":35},
    {"label":"Chinese Yuan","value":"CNY","symbol":"¥","id":36},
    {"label":"Colombian Peso","value":"COP","symbol":"$","id":37},
    {"label":"Comorian Franc","value":"KMF","symbol":"CF","id":38},
    {"label":"Congolese Franc","value":"CDF","symbol":"FC","id":39},
    {"label":"Costa Rican ColÃ³n","value":"CRC","symbol":"₡","id":40},
    {"label":"Croatian Kuna","value":"HRK","symbol":"kn","id":41},
    {"label":"Cuban Convertible Peso","value":"CUC","symbol":"$, CUC","id":42},
    {"label":"Czech Republic Koruna","value":"CZK","symbol":"Kč","id":43},
    {"label":"Danish Krone","value":"DKK","symbol":"Kr.","id":44},
    {"label":"Djiboutian Franc","value":"DJF","symbol":"Fdj","id":45},
    {"label":"Dominican Peso","value":"DOP","symbol":"$","id":46},
    {"label":"East Caribbean Dollar","value":"XCD","symbol":"$","id":47},
    {"label":"Egyptian Pound","value":"EGP","symbol":"ج.م","id":48},
    {"label":"Eritrean Nakfa","value":"ERN","symbol":"Nfk","id":49},
    {"label":"Estonian Kroon","value":"EEK","symbol":"kr","id":50},
    {"label":"Ethiopian Birr","value":"ETB","symbol":"Nkf","id":51},
    {"label":"Euro","value":"EUR","symbol":"€","id":52},
    {"label":"Falkland Islands Pound","value":"FKP","symbol":"£","id":53},
    {"label":"Fijian Dollar","value":"FJD","symbol":"FJ$","id":54},
    {"label":"Gambian Dalasi","value":"GMD","symbol":"D","id":55},
    {"label":"Georgian Lari","value":"GEL","symbol":"ლ","id":56},
    {"label":"German Mark","value":"DEM","symbol":"DM","id":57},
    {"label":"Ghanaian Cedi","value":"GHS","symbol":"GH₵","id":58},
    {"label":"Gibraltar Pound","value":"GIP","symbol":"£","id":59},
    {"label":"Greek Drachma","value":"GRD","symbol":"₯, Δρχ, Δρ","id":60},
    {"label":"Guatemalan Quetzal","value":"GTQ","symbol":"Q","id":61},
    {"label":"Guinean Franc","value":"GNF","symbol":"FG","id":62},
    {"label":"Guyanaese Dollar","value":"GYD","symbol":"$","id":63},
    {"label":"Haitian Gourde","value":"HTG","symbol":"G","id":64},
    {"label":"Honduran Lempira","value":"HNL","symbol":"L","id":65},
    {"label":"Hong Kong Dollar","value":"HKD","symbol":"$","id":66},
    {"label":"Hungarian Forint","value":"HUF","symbol":"Ft","id":67},
    {"label":"Icelandic KrÃ³na","value":"ISK","symbol":"kr","id":68},
    {"label":"Indian Rupee","value":"INR","symbol":"₹","id":69},
    {"label":"Indonesian Rupiah","value":"IDR","symbol":"Rp","id":70},
    {"label":"Iranian Rial","value":"IRR","symbol":"﷼","id":71},
    {"label":"Iraqi Dinar","value":"IQD","symbol":"د.ع","id":72},
    {"label":"Israeli New Sheqel","value":"ILS","symbol":"₪","id":73},
    {"label":"Italian Lira","value":"ITL","symbol":"L,£","id":74},
    {"label":"Jamaican Dollar","value":"JMD","symbol":"J$","id":75},
    {"label":"Japanese Yen","value":"JPY","symbol":"¥","id":76},
    {"label":"Jordanian Dinar","value":"JOD","symbol":"ا.د","id":77},
    {"label":"Kazakhstani Tenge","value":"KZT","symbol":"лв","id":78},
    {"label":"Kenyan Shilling","value":"KES","symbol":"KSh","id":79},
    {"label":"Kuwaiti Dinar","value":"KWD","symbol":"ك.د","id":80},
    {"label":"Kyrgystani Som","value":"KGS","symbol":"лв","id":81},
    {"label":"Laotian Kip","value":"LAK","symbol":"₭","id":82},
    {"label":"Latvian Lats","value":"LVL","symbol":"Ls","id":83},
    {"label":"Lebanese Pound","value":"LBP","symbol":"£","id":84},
    {"label":"Lesotho Loti","value":"LSL","symbol":"L","id":85},
    {"label":"Liberian Dollar","value":"LRD","symbol":"$","id":86},
    {"label":"Libyan Dinar","value":"LYD","symbol":"د.ل","id":87},
    {"label":"Lithuanian Litas","value":"LTL","symbol":"Lt","id":88},
    {"label":"Macanese Pataca","value":"MOP","symbol":"$","id":89},
    {"label":"Macedonian Denar","value":"MKD","symbol":"ден","id":90},
    {"label":"Malagasy Ariary","value":"MGA","symbol":"Ar","id":91},
    {"label":"Malawian Kwacha","value":"MWK","symbol":"MK","id":92},
    {"label":"Malaysian Ringgit","value":"MYR","symbol":"RM","id":93},
    {"label":"Maldivian Rufiyaa","value":"MVR","symbol":"Rf","id":94},
    {"label":"Mauritanian Ouguiya","value":"MRO","symbol":"MRU","id":95},
    {"label":"Mauritian Rupee","value":"MUR","symbol":"₨","id":96},
    {"label":"Mexican Peso","value":"MXN","symbol":"$","id":97},
    {"label":"Moldovan Leu","value":"MDL","symbol":"L","id":98},
    {"label":"Mongolian Tugrik","value":"MNT","symbol":"₮","id":99},
    {"label":"Moroccan Dirham","value":"MAD","symbol":"MAD","id":100},
    {"label":"Mozambican Metical","value":"MZM","symbol":"MT","id":101},
    {"label":"Myanmar Kyat","value":"MMK","symbol":"K","id":102},
    {"label":"Namibian Dollar","value":"NAD","symbol":"$","id":103},
    {"label":"Nepalese Rupee","value":"NPR","symbol":"₨","id":104},
    {"label":"Netherlands Antillean Guilder","value":"ANG","symbol":"ƒ","id":105},
    {"label":"New Taiwan Dollar","value":"TWD","symbol":"$","id":106},
    {"label":"New Zealand Dollar","value":"NZD","symbol":"$","id":107},
    {"label":"Nicaraguan CÃ³rdoba","value":"NIO","symbol":"C$","id":108},
    {"label":"Nigerian Naira","value":"NGN","symbol":"₦","id":109},
    {"label":"North Korean Won","value":"KPW","symbol":"₩","id":110},
    {"label":"Norwegian Krone","value":"NOK","symbol":"kr","id":111},
    {"label":"Omani Rial","value":"OMR","symbol":".ع.ر","id":112},
    {"label":"Pakistani Rupee","value":"PKR","symbol":"₨","id":113},
    {"label":"Panamanian Balboa","value":"PAB","symbol":"B/.","id":114},
    {"label":"Papua New Guinean Kina","value":"PGK","symbol":"K","id":115},
    {"label":"Paraguayan Guarani","value":"PYG","symbol":"₲","id":116},
    {"label":"Peruvian Nuevo Sol","value":"PEN","symbol":"S/.","id":117},
    {"label":"Philippine Peso","value":"PHP","symbol":"₱","id":118},
    {"label":"Polish Zloty","value":"PLN","symbol":"zł","id":119},
    {"label":"Qatari Rial","value":"QAR","symbol":"ق.ر","id":120},
    {"label":"Romanian Leu","value":"RON","symbol":"lei","id":121},
    {"label":"Russian Ruble","value":"RUB","symbol":"₽","id":122},
    {"label":"Rwandan Franc","value":"RWF","symbol":"FRw","id":123},
    {"label":"Salvadoran ColÃ³n","value":"SVC","symbol":"₡","id":124},
    {"label":"Samoan Tala","value":"WST","symbol":"SAT","id":125},
    {"label":"Saudi Riyal","value":"SAR","symbol":"﷼","id":126},
    {"label":"Serbian Dinar","value":"RSD","symbol":"din","id":127},
    {"label":"Seychellois Rupee","value":"SCR","symbol":"SRe","id":128},
    {"label":"Sierra Leonean Leone","value":"SLL","symbol":"Le","id":129},
    {"label":"Singapore Dollar","value":"SGD","symbol":"$","id":130},
    {"label":"Slovak Koruna","value":"SKK","symbol":"Sk","id":131},
    {"label":"Solomon Islands Dollar","value":"SBD","symbol":"Si$","id":132},
    {"label":"Somali Shilling","value":"SOS","symbol":"Sh.so.","id":133},
    {"label":"South African Rand","value":"ZAR","symbol":"R","id":134},
    {"label":"South Korean Won","value":"KRW","symbol":"₩","id":135},
    {"label":"Special Drawing Rights","value":"XDR","symbol":"SDR","id":136},
    {"label":"Sri Lankan Rupee","value":"LKR","symbol":"Rs","id":137},
    {"label":"St. Helena Pound","value":"SHP","symbol":"£","id":138},
    {"label":"Sudanese Pound","value":"SDG","symbol":".س.ج","id":139},
    {"label":"Surinamese Dollar","value":"SRD","symbol":"$","id":140},
    {"label":"Swazi Lilangeni","value":"SZL","symbol":"E","id":141},
    {"label":"Swedish Krona","value":"SEK","symbol":"kr","id":142},
    {"label":"Swiss Franc","value":"CHF","symbol":"CHf","id":143},
    {"label":"Syrian Pound","value":"SYP","symbol":"LS","id":144},
    {"label":"São Tomé and Príncipe Dobra","value":"STD","symbol":"Db","id":145},
    {"label":"Tajikistani Somoni","value":"TJS","symbol":"SM","id":146},
    {"label":"Tanzanian Shilling","value":"TZS","symbol":"TSh","id":147},
    {"label":"Thai Baht","value":"THB","symbol":"฿","id":148},
    {"label":"Tongan Pa'anga","value":"TOP","symbol":"$","id":149},
    {"label":"Trinidad & Tobago Dollar","value":"TTD","symbol":"$","id":150},
    {"label":"Tunisian Dinar","value":"TND","symbol":"ت.د","id":151},
    {"label":"Turkish Lira","value":"TRY","symbol":"₺","id":152},
    {"label":"Turkmenistani Manat","value":"TMT","symbol":"T","id":153},
    {"label":"Ugandan Shilling","value":"UGX","symbol":"USh","id":154},
    {"label":"Ukrainian Hryvnia","value":"UAH","symbol":"₴","id":155},
    {"label":"United Arab Emirates Dirham","value":"AED","symbol":"إ.د","id":156},
    {"label":"Uruguayan Peso","value":"UYU","symbol":"$","id":157},
    {"label":"US Dollar","value":"USD","symbol":"$","id":158},
    {"label":"Uzbekistan Som","value":"UZS","symbol":"лв","id":159},
    {"label":"Vanuatu Vatu","value":"VUV","symbol":"VT","id":160},
    {"label":"Venezuelan BolÃvar","value":"VEF","symbol":"Bs","id":161},
    {"label":"Vietnamese Dong","value":"VND","symbol":"₫","id":162},
    {"label":"Yemeni Rial","value":"YER","symbol":"﷼","id":163},
    {"label":"Zambian Kwacha","value":"ZMK","symbol":"ZK","id":164}
]

export default currencyList