import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Login | ",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "Login | ",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    meta: {
      title: "Register | ",
    },
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: () => import("../components/registration/VerifyEmail.vue"),
    meta: {
      title: "VerifyEmail | ",
    },
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: () => import("../views/ForgetPassword.vue"),
    meta: {
      title: "Forgot Password | ",
    },
  },
  {
    path: "/verification/:token",
    name: "Verification",
    component: () => import("../views/Verification.vue"),
    meta: {
      title: "Verification | ",
    },
  },
  {
    path: "/login-verification",
    name: "LoginVerification",
    component: () => import("../views/LoginVerification.vue"),
    meta: {
      title: "LoginVerification | ",
    },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("../views/ChangePassword.vue"),
    meta: {
      title: "Reset Password | ",
    },
  },
  {
    path: "/xero-connection",
    name: "XeroConnection",
    component: () => import("../views/XeroConnection.vue"),
    meta: {
      auth: true,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/no-organisation",
        name: "NoOrganisation",
        component: () => import("@/components/dashboard/NoWorkspace.vue"),
        meta: {
          auth: true,
          title: "No Organisation | ",
        },
      },
      {
        path: "/integration",
        name: "Integration",
        component: () => import("../views/Integration.vue"),
        meta: {
          auth: true,
          title: "Integration | ",
        },
      },
      {
        path: "/reports/",
        name: "Reports",
        component: () => import("../views/GenerateReports.vue"),
        meta: {
          auth: true,
          title: "Reports | ",
        },
        children: [
          {
            path: "",
            name: "Reports",
            component: () => import("../components/reports/Overview.vue"),
            meta: {
              auth: true,
              title: "Overview | ",
            },
          },
          {
            path: "fpa",
            name: "Reports",
            component: () => import("../components/reports/Fpa.vue"),
            meta: {
              auth: true,
              title: "FPA | ",
            },
          },
          {
            path: "accounting",
            name: "Reports",
            component: () => import("../components/reports/Accounting.vue"),
            meta: {
              auth: true,
              title: "Accounting | ",
            },
          },
          {
            path: "accounts",
            name: "Reports",
            component: () =>
              import("../components/reports/financial-reports/Accounts.vue"),
            meta: {
              auth: true,
              title: "Accounts | ",
            },
          },
          {
            path: "journals",
            name: "Reports",
            component: () =>
              import("../components/reports/financial-reports/Journals.vue"),
            meta: {
              auth: true,
              title: "Journals | ",
            },
          },
          {
            path: "general-ledger",
            name: "Reports",
            component: () =>
              import(
                "../components/reports/financial-reports/GeneralLedger.vue"
              ),
            meta: {
              auth: true,
              title: "GeneralLedger | ",
            },
          },
          {
            path: "trial-balance",
            name: "Reports",
            component: () =>
              import(
                "../components/reports/financial-reports/TrialBalance.vue"
              ),
            meta: {
              auth: true,
              title: "TrialBalance | ",
            },
          },
          {
            path: "balance-sheet",
            name: "Reports",
            component: () =>
              import(
                "../components/reports/financial-reports/BalanceSheet.vue"
              ),
            meta: {
              auth: true,
              title: "BalanceSheet | ",
            },
          },
          {
            path: "profit-loss",
            name: "Reports",
            component: () =>
              import(
                "../components/reports/financial-reports/ProfitAndLoss.vue"
              ),
            meta: {
              auth: true,
              title: "ProfitAndLoss | ",
            },
          },
        ],
      },

      {
        path: "/transaction/",
        name: "Transactions",
        component: () => import("@/views/Transactions.vue"),
        // component: () => import("@/views/dashboard/GenerateRequests.vue"),
        meta: {
          auth: true,
          title: "Transactions | ",
        },
        children: [
          {
            path: "",
            name: "Transactions",
            childName: "Transactions",
            component: () => import("../views/dashboard/GenerateRequests.vue"),
            meta: {
              auth: true,
              title: "Transaction | ",
            },
          },
          {
            path: "invoice",
            name: "Transactions",
            childName: "Invoice",
            component: () => import("../components/transactions/index.vue"),
            meta: {
              auth: true,
              title: "Invoice | ",
            },
          },
          {
            path: "bill",
            name: "Transactions",
            childName: "Bill",
            component: () => import("../components/transactions/index.vue"),
            meta: {
              auth: true,
              title: "Bill | ",
            },
          },
          {
            path: "payment",
            name: "Transactions",
            childName: 'Payment',
            component: () => import("../components/transactions/index.vue"),
            meta: {
              auth: true,
              title: "Payment | ",
            },
          },
        ],
      },
      {
        path: "/generate-request",
        name: "GenerateRequests",
        component: () => import("../views/RequestHistory.vue"),
        meta: {
          auth: true,
          title: "Generate Requests | ",
        },
      },
      {
        path: "/settings/",
        name: "Settings",
        component: () => import("@/views/Settings.vue"),
        meta: {
          auth: true,
          title: "Settings | ",
        },
        children: [
          {
            path: "",
            name: "Profile",
            component: () =>
              import("../components/profile_settings/settings/Profile.vue"),
            meta: {
              auth: true,
              title: "Profile | ",
            },
          },
          {
            path: "organization",
            name: "Organization",
            component: () =>
              import(
                "../components/profile_settings/settings/Organization.vue"
              ),
            meta: {
              auth: true,
              title: "Organization | ",
            },
          },
          {
            path: "manage-team",
            name: "ManageTeam",
            component: () =>
              import("../components/profile_settings/settings/ManageTeam.vue"),
            meta: {
              auth: true,
              title: "ManageTeam | ",
            },
          },
          {
            path: "subscription",
            name: "Subscription",
            component: () =>
              import(
                "../components/profile_settings/settings/Subscriptions.vue"
              ),
            meta: {
              auth: true,
              title: "Subscriptions | ",
            },
          },
        ],
      },
      {
        path: "/requests-history/:workspaceId/:ticketId",
        name: "TicketDetails",
        component: () => import("@/views/dashboard/GenerateRequests.vue"),
        meta: {
          auth: true,
          title: "TicketDetails | ",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routesWithOrWithOutAuth = ["Verification"];
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.meta.auth) {
    if (token) {
      store.dispatch("auth/getProfile");
      next();
    } else next({ path: "/" });
  } else {
    if (token && !routesWithOrWithOutAuth.includes(to.name)) {
      store.dispatch("auth/getProfile");
      next({ path: "/dashboard" });
    } else next();
  }
});

export default router;
