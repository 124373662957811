import Vue from "vue";
import moment from "moment";
import currencyList from "../constants/sign-up/currencyList.js";

var filter = function (text, length, clamp) {
  clamp = clamp || "...";
  var node = document.createElement("div");
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};
Vue.filter("truncate", filter);
Vue.filter("formatDate", function (value) {
  if (!value) return "N/A";
  return moment(value).format("DD MMM YYYY");
});
Vue.filter("formatTime", function (value) {
  if (!value) return "N/A";
  return moment(value).format("hh:mm A");
});
Vue.filter("msgDate", function (value) {
  if (!value) return "";
  return moment(value).format("DD/MM/YYYY hh:mm:ss");
});
Vue.filter("formatISODate", function (value) {
  if (!value) return "";
  return moment(value).format("YYYY-MM-DD");
});

Vue.filter("handleNegativeValue", function (value) {
  if (!value) return 0;
  if (value < 0) {
    return `(${Math.abs(value).toLocaleString()})`;
  } else return value.toLocaleString();
});

Vue.filter("baseCurrency", function (val) {
  if (!val) return "$";
  let curr = currencyList.find((currency) => {
    if (currency.value === val) {
      return currency.symbol;
    }
  });
  return curr.symbol;
});
