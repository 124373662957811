<template>
  <v-dialog v-if="modal" v-model="modal" persistent max-width="550px">
    <v-card class="pb-2">
      <v-card-title class="d-flex justify-space-between text-heading pb-0"
        ><p
          class="fs-18 f-inter font-weight-medium primary--text"
          style="letter-spacing: -0.3px"
        >
          Subscription Expired
        </p>
      </v-card-title>
      <v-divider></v-divider>
      <v-container class="py-0 pt-1">
        <v-card-text class="py-0">
          Subscription has been expired, Kindly update the subscription.
        </v-card-text>

        <v-card-actions class="mt-3">
          <v-col cols="12" class="pb-0 pr-0 d-flex">
            <v-spacer></v-spacer>

            <Button @button-clicked="reRouteToSubscription()" class="mr-2">
              <template v-slot:buttonHeading>
                <p
                  class="ma-0 pa-0 primary--text ="
                  style="letter-spacing: -0.3px"
                >
                  Subscribe Now
                </p>
              </template>
            </Button>
          </v-col>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "../common/button";

export default {
  name: "WorkspacePackageDisbale",

  data() {
    return {
      allowedRouteArray: [
        "Login",
        "Register",
        "ForgetPassword",
        "Verification",
        "VerifyEmail",
        "LoginVerification",
        "ChangePassword",
        "Subscription",
      ],
    };
  },
  components: {
    Button,
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
      currentPackage: "auth/getPackage",
    }),
    modal() {
      let retunValue = false;
      if (
        this.currentPackage?.status === "expired" &&
        !this.allowedRouteArray.includes(this.$route.name)
      )
        retunValue = true;
      return retunValue;
    },
  },
  methods: {
    reRouteToSubscription() {
      this.$router.push({ path: "/settings/subscription" });
    },
  },
};
</script>

<style scoped></style>
